<template>
  <div>
    <TitleBar
      :top-title="title"
      :top-left="'back'"
    />
    <div style="margin:0 auto; padding:50px; max-width:800px; font-size:1.5rem; line-height:2.5rem">
      <p>○ 이 페이지는 다국어 지원을 위한 JSON 생성 관리자 툴입니다.</p>
      <p>○ 한국어로 되어있는 "_kr" JSON 파일을 번역하고, 홀로매직에 저장합니다.</p>
      <p>○ "conts_list_trnas_XX"으로 해당 국가의 콘텐츠 목록을 세부 설정할 수 있습니다.</p>
      <div style="padding-left:25px">
        <p>- 이 파일은 홀로매직 서비스에 직접 사용되는 파일이 아닙니다.</p>
        <p>- 언어별로 콘텐츠 목록을 다르게 설정할 수 있습니다.</p>
        <p>- "conts_list_kr"과 비슷한 형식이며, 제목 대신에 자동 번역 여부를 기록합니다.</p>
        <p>- 값이 1인 콘텐츠만 번역하고, 0인 경우는 번역하지 않고, 보존합니다.</p>
        <p>- 이 파일이 없는 경우 conts_list_kr 전체를 번역합니다.</p>
      </div>
      <hr style="margin:20px 0">
      <p>○ SOURCE JSON : conts_list_kr, conts_descr_kr</p>
      <p>○ TARGET JSON : conts_list_XX, conts_descr_XX</p>
      <hr style="margin:20px 0">
      <p>
        ○ 번역 할 언어 :
        <select
          v-model="target_lang"
          style="margin-top:-7px; padding:0 5px; font-size:1.5rem; font-weight:bold; background-color:inherit; color:inherit"
          @change="onChangeLang"
        >
          <option
            v-for="el in langSelect.filter((el) => el[0] != 'kr')"
            :key="el[0]"
            :value="el[0]"
          >
            {{ el[1] }}
          </option>
        </select>
      </p>
      <p>○ 번역되어있지 않은 콘텐츠의 개수 : {{ target_ea }}</p>
    </div>
    <div style="text-align:center">
      <input
        type="button"
        :value="btn_tran"
        style="font-size:1.5rem; line-height:2.5rem"
        @click="goTrans"
      >
    </div>
    <div
      id="trans_work_area"
      style="position:absolute; top:0; width:100%; visibility:hidden"
    >
      <iframe
        src="/embed_translate.html"
        style="width:100%"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TitleBar from '@/components/navi/TitleBar.vue';
import axios from 'axios';

export default {
  metaInfo () {
    return {
      title: this.title,
    };
  },
  components: {
    TitleBar,
  },
  data () {
    return {
      title: '번역 (관리자용)',
      target_lang: '',
      target_ea: 0,
      isRuning: false,
    };
  },
  computed: {
    btn_tran () {
      return this.isRuning ? '번역 진행중...' : '번역 시작';
    }
  },
  mounted () {
    (async () => {
      await axios.get(`${this.$store.state.apiHost}/api/copyJsonLang`);
      await this.loadBasic();
      // this.target_lang = this.$store.state.lang == 'kr' ? this.langSelect[0][0] : this.$store.state.lang;
      // this.getCount();
    })();
  },
  methods: {
    ...mapActions({
      loadBasic: 'loadBasic',
    }),
    getCount () {
      axios.post(`${this.$store.state.apiHost}/api/translate`, { type: 'count', lang: this.target_lang }).then((res) => {
        this.target_ea = res.data.source_nn - res.data.target_nn;
      });
    },
    goTrans () {
      if (this.isRuning) return;
      this.isRuning = true;

      if (!this.target_lang) {
        this.showAlert('번역', '번역 할 언어를 선택하세요.');
        this.isRuning = false;
        return;
      }

      let isNew = true;
      (async () => {
        while (this.isRuning) {
          const src = await axios.post(`${this.$store.state.apiHost}/api/translate`, { type: 'read', lang: this.target_lang, isNew: isNew });
          isNew = false;
          this.getCount();

          if (src.data.arr_trans.length) {
            const conWin = document.querySelector('#trans_work_area > iframe').contentWindow;
            const res = await conWin.fTrans(this.langName[this.target_lang], src.data.arr_trans);
            await axios.post(`${this.$store.state.apiHost}/api/translate`, { type: 'write', lang: this.target_lang, arr_trans: res });
          } else {
            axios.get(`${this.$store.state.apiHost}/api/copyJsonLang`);
            this.isRuning = false;
          }
        }
      })();
    },
    onChangeLang () {
      this.getCount();
    },
  }
};
</script>

<style scoped>
</style>
